import { Project, ProjectStage } from '@tb/common';
import type { ColDef, ICellRendererParams } from 'ag-grid-community';
import { useRouter } from 'next/router';
import {
  DataGrid,
  currencyValueFormatter,
  useColorModeStuff,
  useDataGrid,
} from '~/components/common';
import { projectStageBadge } from '~/utils/projectStageFormat';

export const OverviewProjectsList = ({ rowData }: { rowData: Project[] }) => {
  const { colorModeSaturation5 } = useColorModeStuff();
  const router = useRouter();
  const columnDefs: ColDef<Project>[] = [
    { headerName: 'ID', field: 'code', width: 100 },
    { headerName: 'Name', field: 'name', tooltipField: 'name' },
    { headerName: 'Address', field: 'address', tooltipField: 'address' },
    { headerName: 'Estimate Owner', field: 'owner', tooltipField: 'owner' },
    { headerName: 'Assigned To', field: 'assignee', tooltipField: 'assignee' },
    {
      headerName: 'Stage',
      field: 'stage',
      filter: 'agTextColumnFilter',
      cellRenderer: (params: ICellRendererParams<Project, ProjectStage>) => {
        if (params.value) {
          return projectStageBadge(params, colorModeSaturation5);
        }
      },
    },
    {
      headerName: 'Total Cost',
      field: 'projectValue',
      valueFormatter: currencyValueFormatter,
      maxWidth: 160,
    },
  ];

  const dataGridModel = useDataGrid<Project>({
    rows: rowData,
    columns: columnDefs,
    onRowSelected: ({ data: row }) => {
      if (row) {
        router.push(`/projects/${row.id}`);
      }
    },
  });

  return <DataGrid model={dataGridModel} />;
};

export const OverviewProjectsListView = ({
  projects,
}: {
  projects: Project[];
}) => {
  return <OverviewProjectsList rowData={projects} />;
};
